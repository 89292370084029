
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: [],
  components: {},
})
export default class TopBar extends Vue {
  showMenu = false;

  topMenu(): void {
    this.showMenu = !this.showMenu;
  }

  mounted(): void {
    console.log("~top-bar");
  }
}
