
import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import BottomFooter from "@/components/BottomFooter.vue";

@Component({
  props: ["required"],
  components: { TopBar, Breadcrumbs, BottomFooter },
})
export default class ContactUs extends Vue {}
