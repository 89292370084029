import OurWork from "@/views/OurWork.vue";
import OurServices from "@/views/OurServices.vue";
import ContactUs from "@/views/ContactUs.vue";
import AboutUs from "@/views/AboutUs.vue";
import Home from "../views/Home.vue";
import VueRouter, { RouteConfig } from "vue-router";
import Vue from "vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/about-us",
    name: "About Us",
    component: AboutUs,
  },
  {
    path: "/our-work",
    name: "Our Work",
    component: OurWork,
  },
  {
    path: "/our-services",
    name: "Our Services",
    component: OurServices,
  },
  {
    path: "/contact-us",
    name: "Contact Us",
    component: ContactUs,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
