
import { Component, Vue } from "vue-property-decorator";
import TopBar from "../components/TopBar.vue";
import Swipper from "./Swipper.vue";

@Component({
  components: {
    TopBar,
    Swipper,
  },
})
export default class Home extends Vue {}
