
import { Component, Vue } from "vue-property-decorator";
import TopBar from "@/components/TopBar.vue";
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import BottomFooter from "@/components/BottomFooter.vue";

@Component({
  props: ["required"],
  components: { TopBar, Breadcrumbs, BottomFooter },
})
export default class OurWork extends Vue {
  galleries = [
    {
      title: "Gutters & Downspouts",
      images: [
        require("../assets/projects/17.jpeg"),
        require("../assets/projects/29.jpeg"),
        require("../assets/projects/6.jpeg"),
        require("../assets/projects/7.jpeg"),
        require("../assets/projects/18.jpeg"),
        require("../assets/projects/29.jpeg"),
      ],
    },
    {
      title: "Aluminium & Glass Railing",
      images: [
        require("../assets/projects/33.jpeg"),
        require("../assets/projects/34.jpeg"),
        require("../assets/projects/35.jpeg"),
        require("../assets/projects/36.jpeg"),
        require("../assets/projects/37.jpeg"),
        require("../assets/projects/38.jpeg"),
        require("../assets/projects/39.jpeg"),
      ],
    },
    {
      title: "Soffit",
      images: [
        require("../assets/projects/25.jpeg"),
        require("../assets/projects/26.jpeg"),
        require("../assets/projects/27.jpeg"),
        require("../assets/projects/28.jpeg"),
      ],
    },
    {
      title: "Aluminium & Vinyl Siding",
      images: [
        require("../assets/projects/3.jpeg"),
        require("../assets/projects/10.jpeg"),
        require("../assets/projects/24.jpeg"),
        require("../assets/projects/32.jpeg"),
        require("../assets/projects/20.jpeg"),
        require("../assets/projects/40.jpeg"),
      ],
    },
    {
      title: "Metal Panels",
      images: [
        require("../assets/projects/22.jpeg"),
        require("../assets/projects/21.jpeg"),
      ],
    },
    {
      title: "Metal Cladding",
      images: [require("../assets/projects/23.jpeg")],
    },
    {
      title: "Fascia",
      images: [
        require("../assets/projects/4.jpeg"),
        require("../assets/projects/5.jpeg"),
        require("../assets/projects/7.jpeg"),
      ],
    },
  ];
}
