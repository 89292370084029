
import { Component, Vue } from "vue-property-decorator";
import { bus } from "../main";
import Slides from "../views/Slides.vue";

@Component({
  components: {
    Slides,
  },
})
export default class Swipper extends Vue {
  delay = 200;
  clicks = 0;
  timer: any = null;
  clickX = 0;
  clickY = 0;
  direction = "";
  storyId: unknown;

  tapHandler(event: any): void {
    this.clicks++;
    if (this.clicks === 1) {
      this.timer = setTimeout(() => {
        this.clicks = 0;
        this.singleClick(event);
      }, this.delay);
    } else {
      clearTimeout(this.timer);
      this.clicks = 0;
      this.doubleClick(event);
    }
  }
  singleClick(e: any): void {
    if (e.x) {
      this.clickX = e.x;
    } else {
      this.clickX = e.changedTouches[0].clientX;
    }
    if (this.clickX > window.innerWidth / 2) {
      bus.$emit("action", "left");
    } else {
      bus.$emit("action", "right");
    }
  }
  doubleClick(e: any): void {
    if (e.y) {
      this.clickY = e.y;
    } else {
      this.clickY = e.changedTouches[0].clientY;
    }
    if (this.clickY < window.innerHeight / 2) {
      bus.$emit("action", "bottom");
    } else {
      bus.$emit("action", "top");
    }
  }
  swipeHandler(d: unknown): void {
    bus.$emit("action", d);
  }
  scrollHandler(e: any): void {
    if (e.deltaY > 0) {
      bus.$emit("action", "top");
    } else {
      bus.$emit("action", "bottom");
    }
  }
  keypressHandler(e: any): void {
    if (e.key == "ArrowDown") {
      bus.$emit("action", "top");
    } else if (e.key == "ArrowUp") {
      bus.$emit("action", "bottom");
    } else if (e.key == "ArrowLeft") {
      bus.$emit("action", "right");
    } else if (e.key == "ArrowRight") {
      bus.$emit("action", "left");
    }
  }
}
