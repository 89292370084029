
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { Component, Vue } from "vue-property-decorator";
import TopBar from "../components/TopBar.vue";
import BottomFooter from "../components/BottomFooter.vue";

@Component({
  props: [],
  components: { Breadcrumbs, TopBar, BottomFooter },
})
export default class AboutUs extends Vue {
  mounted(): void {
    console.log("~about-us");
  }
}
