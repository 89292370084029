
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import { Component, Vue } from "vue-property-decorator";

@Component({
  props: {
    title: {
      type: String,
      default: "404 | Not Found",
    },
  },
  components: { Breadcrumbs },
})
export default class Home extends Vue {
  mounted(): void {
    console.log("~about-us");
  }
}
