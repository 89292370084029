
import { Component, Vue } from "vue-property-decorator";
import { bus } from "../main";
import router from "../router";
import Dashes from "../components/Dashes.vue";

@Component({
  props: ["required"],
  components: {
    Dashes,
  },
})
export default class Slides extends Vue {
  gallery = [
    require("../assets/igutters-home.jpg"),
    "https://images.unsplash.com/photo-1524568292009-5c8f3fe5dcc7?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    "https://images.unsplash.com/photo-1607810991928-7e609a664e8d?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1950&q=80",
    "https://images.unsplash.com/photo-1607889040550-1b9f7c2ebbb5?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
    "https://images.unsplash.com/photo-1585968895333-12f7ce1bf1bd?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1950&q=80",
    "https://images.unsplash.com/photo-1549517045-bc93de075e53?ixlib=rb-1.2.1&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=1951&q=80",
    "https://images.unsplash.com/photo-1492943301880-e4b772f94dc6?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=1952&q=80",
  ];
  titles = [
    "Welcome to iGutters Inc. We offer services in",
    "iGutters Inc offers services in Seamless Gutters",
    "iGutters Inc offers services in Siding",
    "iGutters Inc offers services in Capping",
    "iGutters Inc offers services in Soffit",
    "iGutters Inc offers services in Fascia",
    "iGutters Inc offers services in Railing",
  ];
  subtitles = [
    " We are a dedicated team, offering proffesional service and premium materials.",
    "This is our main service and we do it best.",
    "Trust us the sidding and your home will love you.",
    "Boost your home capping with us.",
    "The soffit your home deserves.",
    "Get your fascia done faster and better.",
    "What your home deserves.",
  ];

  story = 0;
  imgSource = this.gallery[this.story];
  titleSource = this.titles[this.story];
  subtitleSource = this.subtitles[this.story];

  texts = [
    "Seamless Gutters ",
    "Siding ",
    "Capping ",
    "Soffit ",
    "Fascia ",
    "Railing ",
  ];
  count = 0;
  index = 0;
  currentText = "";
  letter = "";
  typeBackward = false;
  typing = document.querySelector(".typing");

  type(): void {
    if (this.count === this.texts.length) {
      this.count = 0;
    }

    if (this.typeBackward) {
      this.currentText = this.texts[this.count];
      this.letter = this.currentText.slice(0, --this.index);

      if (this.typing) {
        this.typing.textContent = this.letter;
      }
      if (this.letter.length === 0) {
        this.typeBackward = false;
        this.count++;
        this.index = 0;
      }
    } else {
      this.currentText = this.texts[this.count];
      this.letter = this.currentText.slice(0, ++this.index);

      if (this.typing) {
        this.typing.textContent = this.letter;
      }
      if (this.letter.length === this.currentText.length) {
        this.typeBackward = true;
      }
    }
    setTimeout(this.type, 150);
  }

  mounted(): void {
    bus.$on("action", (payload: unknown) => {
      if (payload === "right") {
        if (router.currentRoute.name === "Home") {
          this.slideLeft();
        }
      } else if (payload === "left") {
        if (router.currentRoute.name === "Home") {
          this.slideRight();
        }
      } else if (payload === "top") {
        this.slideRight();
      } else if (payload === "bottom") {
        this.slideLeft();
      }
    });
    this.type();
  }
  slideRight(): void {
    if (this.story < this.gallery.length - 1) {
      this.story = this.story + 1;
      this.imgSource = this.gallery[this.story];
      this.titleSource = this.titles[this.story];
      this.subtitleSource = this.subtitles[this.story];
    } else {
      this.story = 0;
      this.imgSource = this.gallery[this.story];
      this.titleSource = this.titles[this.story];
      this.subtitleSource = this.subtitles[this.story];
    }
  }
  slideLeft(): void {
    if (this.story > 0) {
      this.story = this.story - 1;
      this.imgSource = this.gallery[this.story];
      this.titleSource = this.titles[this.story];
      this.subtitleSource = this.subtitles[this.story];
    } else {
      this.story = this.gallery.length - 1;
      this.imgSource = this.gallery[this.story];
      this.titleSource = this.titles[this.story];
      this.subtitleSource = this.subtitles[this.story];
    }
  }
}
